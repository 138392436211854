.topBar{
    align-items: center;
    justify-content: center;
    height: 8vh;
    width: 100%;
    display: flex;
    padding: 2em;
}

.logoBack{
    background: linear-gradient(90deg,  #99C2A2, black);
    width: 30%;
    min-width: 200px;
    max-width: 250px;
}

.topBarLogo{
    width: 20%;
    min-width: 60px;
    max-width: 100px;
}

.topBarMenu{
    justify-content: left;
    font-size: 24px;
    align-items: center;
    justify-content: right;
    color: #99C2A2;
    padding-right: 3em;
}

.filler{
    width: 100%;
}