.section-container {
  height: 700vh;
}

.canvas-container{
    position: sticky;
    top: 0;
}

#scroll-canvas{
    width: 100vw;
    height: 100vh;
    background-color: aqua;
}