
.imageContainer {
    align-items: center;
}

.pharmaContainer {
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
    padding: 4em;
}