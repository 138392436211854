.container {
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
    padding: 4em;
}

.headerContainer {
    display: flex;
    height: 80vh;
    justify-content: center;
    padding-top: 15%;
}

.subContainer {
    display: flex;
    height: 100%;
    width: 33.3%;
    align-items: center;
    justify-content: center;
}