.title {
    font-size: 66px;
    text-align: center;
}

.subTitle {
    background-color: white;
    color: #99C2A2;
    font-size: 28px;
    text-align: center;
}

.textBackground{
    font-family: 'Moirai', sans-serif;
}