.container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    color: white;
}

.subContainer {
    display: flex;
    height: 80%;
    width: 33.3%;
    align-items: center;
    justify-content: center;
}

.title {
    color: white;
    font-size: 32px;
    text-align: center;
}

.subTitle {
    color: white;
    font-size: 28px;
    text-align: center;
}